<template>
  <a-card :bordered="false">
    <div>
      <a-row>
        <a-col :span="$mq === 'mobile' ? 24 : 8">
          <a-form :label-col="{ span: $mq === 'mobile' ? 10 : 8 }" :wrapper-col="{ span: $mq === 'mobile' ? 24 : 15 }">
            <a-form-item label="Tìm khách hàng">
              <a-input-search
                v-model="phoneNumber"
                placeholder="SỐ ĐIỆN THOẠI"
                enter-button
                @search="onSearchAtSalon(false, phoneNumber)"
              />
            </a-form-item>
          </a-form>
        </a-col>
        <a-col :span="16">
          <div class="list-children-phone">
            <div class="text-title-children-phone">Người quen của khách hàng:</div>
            <div class="block-children-phone" v-for="(item, index) in listChildrenPhoneShow" :key="index">
              <a
                :class="['cricle-children-phone', item.id == childSelectItemId ? 'active' : '']"
                @click="viewHistoryChild(item)"
                >{{ item.charaterLastName }}</a
              >
              <a class="name-children-phone" @click="viewHistoryChild(item)">{{ item.customerName }}</a>
            </div>
            <div class="block-children-phone" v-if="numberChildrenPhoneHide > 0">
              <a class="cricle-children-phone" @click="showPopup">+{{ numberChildrenPhoneHide }}</a>
              <div class="other-children-phone" v-if="isShowPopup">
                <div class="block-other-children-phone" v-for="(item, index) in listChildrenPhonePopup" :key="index">
                  <a
                    :class="['cricle-children-phone', item.id == childSelectItemId ? 'active' : '']"
                    @click="viewHistoryChild(item)"
                    >{{ item.charaterLastName }}</a
                  >
                  <a class="name-children-phone" @click="viewHistoryChild(item)">{{ item.customerName }}</a>
                </div>
              </div>
            </div>
            <a-button type="primary" ghost @click="onShowModalFeedBack()" v-if="baseInfo.phone">+ Feedback</a-button>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="8">
        <a-col :span="$mq === 'mobile' ? 24 : 12">
          <div class="d-info-card">
            <a-row>
              <a-col class="d-group-customer-info" :span="$mq === 'mobile' ? 12 : 6">
                <div class="d-label"><a-icon type="user" />Tên khách</div>
                <div class="d-value">{{ baseInfo.name }}</div>
              </a-col>
              <a-col class="d-group-customer-info" :span="$mq === 'mobile' ? 12 : 6">
                <div class="d-label"><a-icon type="phone" />SĐT</div>
                <div class="d-value">{{ baseInfo.phone }}</div>
              </a-col>
              <a-col class="d-group-customer-info" :span="$mq === 'mobile' ? 12 : 6">
                <div class="d-label"><a-icon type="sketch" />Số dư TopUp</div>
                <div class="d-value">
                  {{ baseInfo.accountBalance | toCurrency }} ({{ baseInfo.cashBalance | toCurrency }})
                </div>
              </a-col>
              <a-col class="d-group-customer-info" :span="$mq === 'mobile' ? 12 : 6">
                <div class="d-label"><a-icon type="star" />Shinemember</div>
                <div class="d-value">
                  {{ baseInfo.shineMemberName }}
                  <br />
                  <!-- {{ moment(baseInfo.shineMemberEndDate).format('DD-MM-yyyyThh:mm:ss')}} -->
                </div>
              </a-col>
            </a-row>
          </div>

          <div class="">
            <a-row>
              <div class="header-customer-info">
                <span v-if="!showMoreCustomerInfo" @click="showCustomerInfo(true)"
                  >Chi tiết thông tin khách hàng <a-icon type="caret-down"
                /></span>
                <span v-if="showMoreCustomerInfo" @click="showCustomerInfo(false)"
                  >Ẩn bớt thông tin <a-icon type="caret-up"
                /></span>
              </div>
              <div
                class="customer-info-container"
                v-if="showMoreCustomerInfo && customerInfoNew != null && editCustomerInfo == false"
              >
                <div class="item-customer-info">
                  Ngày sinh: {{ customerInfoNew.dateOfBirth != null ? customerInfoNew.dateOfBirth : '' }}
                </div>
                <div class="item-customer-info">
                  Email: {{ customerInfoNew.email != null ? customerInfoNew.email : '' }}
                </div>
                <div class="item-customer-info">
                  Nghề nghiệp: {{ customerInfoNew.occupation != null ? customerInfoNew.occupation : '' }}
                </div>
                <div class="item-customer-info">
                  Địa chỉ:
                  {{
                    customerInfoNew.addressCustomer != null && customerInfoNew.addressCustomer.addressFull != null
                      ? customerInfoNew.addressCustomer.addressFull
                      : ''
                  }}
                </div>
                <div class="item-customer-info" v-if="$can($permission.edit, $route.path)">
                  <a-button type="default" size="small" class="btn-edit-customer-info" @click="showEditCustomerInfo"
                    ><a-icon type="edit" /> Sửa thông tin</a-button
                  >
                </div>
              </div>
              <div
                class="customer-info-container"
                v-if="showMoreCustomerInfo && customerInfoNew != null && editCustomerInfo"
              >
                <!-- <div class="customer-info-container"> -->
                <div class="item-customer-info">
                  <div class="label-field">Ngày sinh:</div>
                  <div class="input-field">
                    <a-date-picker
                      placeholder="Chọn ngày sinh"
                      format="DD/MM/YYYY"
                      :value="dateOfBirth"
                      :disabledDate="disableBirthdayTime"
                      @change="e => handleChange('birthdayCustomer', e)"
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="item-customer-info">
                  <div class="label-field">Email:</div>
                  <div class="input-field">
                    <a-input v-model="email" @change="checkVakidateEmail" placeholder="Nhập email" />
                    <span class="error-input" v-if="errorEmail">Email không đúng định dạng</span>
                  </div>
                </div>
                <div class="item-customer-info">
                  <div class="label-field">Nghề nghiệp:</div>
                  <div class="input-field">
                    <a-input v-model="occupation" @change="checkVakidateInput" placeholder="Nhập nghề nghiệp" />
                    <span class="error-input" v-if="errorOccupation">Không nhập ký tự đặc biệt</span>
                  </div>
                </div>
                <div class="item-customer-info">
                  <div class="label-field">Địa chỉ:</div>
                  <div class="input-field">
                    <div class="field-select-address">
                      <div class="field-select-address-left">
                        <a-select
                          placeholder="Chọn Tỉnh/Thành Phố"
                          show-search
                          :filter-option="filterCity"
                          :value="cityId"
                          style="width: 100%"
                          @change="e => handleChange('cityCustomer', e)"
                        >
                          <a-select-option v-for="item in listCity" :value="item.cityId" :key="item.cityId">
                            {{ item.city }}
                          </a-select-option>
                        </a-select>
                        <span class="error-input" v-if="errorCity">Vui lòng chọn Tỉnh/Thành Phố</span>
                      </div>
                      <div class="field-select-address-right">
                        <a-select
                          placeholder="Chọn Quận/Huyện"
                          show-search
                          :filter-option="filterDistrict"
                          :value="districtId"
                          :disabled="disableDistrict"
                          style="width: 100%"
                          @change="e => handleChange('districtCustomer', e)"
                        >
                          <a-select-option
                            v-for="item in listDistrictShow"
                            :value="item.districtId"
                            :key="item.districtId"
                          >
                            {{ item.district }}
                          </a-select-option>
                        </a-select>
                        <span class="error-input" v-if="errorDistrict">Vui lòng chọn Quận/Huyện</span>
                      </div>
                    </div>
                    <div class="field-select-address">
                      <div class="field-select-address-left">
                        <a-select
                          placeholder="Chọn Phường/Xã"
                          show-search
                          :filter-option="filterWard"
                          :value="wardId"
                          :disabled="disableWard"
                          style="width: 100%"
                          @change="e => handleChange('wardCustomer', e)"
                        >
                          <a-select-option v-for="item in listWardShow" :value="item.wardId" :key="item.wardId">
                            {{ item.ward }}
                          </a-select-option>
                        </a-select>
                        <span class="error-input" v-if="errorWard">Vui lòng chọn Phường/Xã</span>
                      </div>
                      <div class="field-select-address-right">
                        <a-select
                          placeholder="Chọn Tên Đường"
                          show-search
                          :filter-option="filterStreet"
                          :value="streetId"
                          :disabled="disableStreet"
                          style="width: 100%"
                          @change="e => handleChange('streetCustomer', e)"
                        >
                          <a-select-option v-for="item in listStreetShow" :value="item.streetId" :key="item.streetId">
                            {{ item.street }}
                          </a-select-option>
                        </a-select>
                        <span class="text-note-street">Không tìm thấy Đường thì nhập số nhà và tên đường ở dưới</span>
                      </div>
                    </div>
                    <div class="field-select-address">
                      <a-input placeholder="Nhập chi tiết số nhà, ngõ, ..." v-model="addressDetail"> </a-input>
                    </div>
                  </div>
                </div>
                <div class="item-customer-info">
                  <a-button type="default" size="small" class="btn-edit-customer-info" @click="saveCustomerInfo"
                    ><a-icon type="save" /> Lưu</a-button
                  >
                  <a-button type="default" size="small" class="btn-cancel-customer-info" @click="cancelCustomerInfo"
                    ><a-icon type="stop" /> Hủy</a-button
                  >
                </div>
              </div>
            </a-row>
            <!-- memberlog area  -->
            <a-row>
              <div class="header-customer-info">
                <span v-if="!showMemberLog" @click="showMemberLogInfo(true)"
                  >Chi tiết lịch sử mua shine member <a-icon type="caret-down"
                /></span>
                <span v-if="showMemberLog" @click="showMemberLogInfo(false)"
                  >Ẩn bớt thông tin <a-icon type="caret-up"
                /></span>
              </div>
              <div class="customer-info-container" v-if="showMemberLog === true && memberLog.length > 0">
                <a-table
                  :columns="memberLogColumns"
                  :data-source="memberLog"
                  :locale="{
                    emptyText: 'Không có dữ liệu'
                  }"
                  :pagination="{
                    defaultPageSize: 3,
                    showSizeChanger: true,
                    pageSizeOptions: ['3', '5', '10']
                  }"
                >
                  <div slot="startDate" slot-scope="text">{{ text | formatDate }}</div>
                  <div slot="endDate" slot-scope="text">{{ text | formatDate }}</div>
                </a-table>
              </div>
            </a-row>
            <!-- product history  -->
            <a-row>
              <div class="header-customer-info">
                <span v-if="!showProductHistory" @click="showProductHistoryInfo(true)"
                  >Chi tiết lịch sử mua sản phẩm <a-icon type="caret-down"
                /></span>
                <span v-if="showProductHistory" @click="showProductHistoryInfo(false)"
                  >Ẩn bớt thông tin <a-icon type="caret-up"
                /></span>
              </div>
              <div class="customer-info-container" v-if="showProductHistory === true && productHistories.length > 0">
                <a-table
                  :columns="productHistoryColumns"
                  :data-source="productHistories"
                  :locale="{
                    emptyText: 'Không có dữ liệu'
                  }"
                  :pagination="{
                    defaultPageSize: 3,
                    showSizeChanger: true,
                    pageSizeOptions: ['3', '5', '10', '20', '50', '100']
                  }"
                >
                  <div slot="priceDot" slot-scope="text">{{ formatPriceWithDot(text) }}</div>
                </a-table>
              </div>
            </a-row>
            <!-- service history  -->
            <a-row>
              <div class="header-customer-info">
                <span v-if="!showServiceHistory" @click="showServiceHistoryInfo(true)"
                  >Chi tiết lịch sử sử dụng dịch vụ <a-icon type="caret-down"
                /></span>
                <span v-if="showServiceHistory" @click="showServiceHistoryInfo(false)"
                  >Ẩn bớt thông tin <a-icon type="caret-up"
                /></span>
              </div>
              <div class="customer-info-container" v-if="showServiceHistory === true && serviceHistories.length > 0">
                <a-table
                  :columns="serviceHistoryColumns"
                  :data-source="serviceHistories"
                  :locale="{
                    emptyText: 'Không có dữ liệu'
                  }"
                  :pagination="{
                    defaultPageSize: 3,
                    showSizeChanger: true,
                    pageSizeOptions: ['3', '5', '10', '20', '50', '100']
                  }"
                >
                  <div slot="priceDotService" slot-scope="text">{{ formatPriceWithDot(text) }}</div>
                </a-table>
              </div>
            </a-row>
          </div>
        </a-col>
        <!-- <a-col :span="$mq === 'mobile' ? 24 : 12">
          <div class="d-tag-info-card">
            <a-row>
              <a-col span="24" class="customer-tag-title">Tags gắn khách</a-col>
            </a-row>
            <a-row v-if="!!baseInfo.customerTags && baseInfo.customerTags.length">
              <a-col span="24">
                <div v-for="(item, index) in baseInfo.customerTags" :key="index" class="customer-tag-item">
                  - {{ item.tag }}
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col> -->

        <a-col
          :span="$mq === 'mobile' ? 24 : 12"
          v-if="shineMemberInfo != null && shineMemberInfo.member != null && shineMemberInfo.member.expire"
        >
          <!-- <div v-for="(tag, index) in baseInfo.generatedTags" :key="index">- {{ tag.customerTag }}</div> -->
          <div class="d-tag-info-card b-member" style="margin-bottom: 2px; width: 100%">
            <item-member-info
              :key="member.memberName"
              v-for="member in shineMemberInfo.member.expire"
              :member="member"
            />
            <span v-if="shineMemberInfo.member.memberType === 2" class="b-member-extend"
              >Tự động lên hạng member khi đạt đủ điều kiện:
            </span>
            <span v-else class="b-member-extend">Tự động gia hạn member khi đạt đủ điều kiện: </span>
            <item-member-spending
              :current="shineMemberInfo.member.spending.current"
              :memberName="shineMemberInfo.member.memberName"
              v-if="shineMemberInfo.member.spending.current"
            />

            <div v-if="shineMemberInfo.member.spending.upgrade">
              <item-member-spending
                :current="member"
                :memberName="member.memberName"
                :isUpgrade="true"
                v-for="member in shineMemberInfo.member.spending.upgrade"
                :key="member.memberName"
              />
            </div>
          </div>
        </a-col>
      </a-row>
      <div class="d-group-button" v-if="$mq != 'mobile'">
        <div class="d-button">
          <a-button
            class="d-salon-button d-button-focus"
            type="default"
            shape="round"
            size="small"
            @click="onSearchAtSalon(selectChild, selectChild ? phoneSelected : phoneNumber)"
            >MUA TẠI SALON</a-button
          >
        </div>
        <div class="d-button">
          <a-button
            class="d-online-button d-button-focus"
            type="default"
            shape="round"
            size="small"
            @click="onSearchAtStoreOnline(selectChild ? phoneSelected : phoneNumber)"
            >MUA ONLINE</a-button
          >
        </div>
        <div class="d-button">
          <a-button
            class="d-online-button d-button-focus"
            type="default"
            shape="round"
            size="small"
            @click="onSearchAtStoreOnlineNew(selectChild ? phoneSelected : phoneNumber, true)"
            >MUA ONLINE MỚI</a-button
          >
        </div>
        <div class="d-button" v-if="isMoyo">
          <a-button
            class="d-online-button d-button-focus"
            type="default"
            shape="round"
            size="small"
            @click="getBillTopup(selectChild ? phoneSelected : phoneNumber, 1, 10)"
            >CHI TIÊU TOPUP</a-button
          >
        </div>
      </div>
      <div v-else class="d-group-radio">
        <a-radio-group v-model="value" @change="onChangeRadioButton">
          <a-radio :value="1">Tại salon</a-radio>
          <a-radio :value="2">Online</a-radio>
        </a-radio-group>
      </div>

      <a-modal v-model="visibleRules" width="700px" :footer="null">
        <div style="color: #20489b;">
          <div style="margin: auto; text-align: center;font-weight: 700; font-size:larger;">
            THỂ LỆ CHƯƠNG TRÌNH TÍCH LŨY
          </div>
          <div class="rule">
            <div class="rule-title-1">1. Đối tượng tham gia:</div>
            <div class="rule-text-1">- Chương trình áp dụng cho tất cả khách hàng member của Moyo</div>
          </div>
          <div class="rule">
            <div class="rule-title-1">2. Cách thức tích lũy:</div>
            <div class="rule-text-1">
              - Khi khách hàng tích lũy đủ 5 lần, sẽ nhận được 1 lần Gội phồng Moyo miễn phí
            </div>
          </div>
          <div class="rule">
            <div class="rule-title-1">3. Điều kiện được tích lũy:</div>
            <div class="rule-text-1">- Hóa đơn thanh toán bằng popup</div>
            <div class="rule-text-1">- Hóa đơn dùng khuyến mãi nhưng có giá trị thanh toán bằng topup lớn hơn 0đ</div>
          </div>
          <div class="rule">
            <div class="rule-title-1">4. Điều kiện không được tích lũy:</div>
            <div class="rule-text-1">- Hóa đơn thanh toán bằng hình thức khác ngoài topup</div>
            <div class="rule-text-1">
              - Hóa đơn được thanh toán bằng tài khoản topup của người kháhc (VD: Lúc đầu thanh toán 100k bằng topup
              khách A, sau đó dùng thêm dịch vụ thanh toán thêm 50k bằng topup khách B => Lần này sẽ được tính tích lũy
              cho tài khoản khách A)
            </div>
            <div class="rule-text-1">- Hóa đơn sử dụng khuyến mãi còn 0đ</div>
          </div>
          <div class="rule" style="display: flex; flex-wrap: wrap;">
            <div class="rule-title-1">5. Thời gian áp dụng:</div>
            <div class="rule-text-1" style="padding-left: 5px;">
              Từ 15/7/2024 cho đến khi có thông báo dừng chương trình
            </div>
          </div>
          <div class="rule" style="display: flex; flex-wrap: wrap;">
            <div class="rule-title-1">6. Liên hệ:</div>
            <div class="rule-text-1" style="padding-left: 5px;">1900.299.993</div>
          </div>
        </div>
      </a-modal>

      <div>
        <a-row>
          <a-col :span="$mq === 'mobile' ? 24 : 12">
            <div style="padding-left: 100px;padding-top: 20px; font-weight: 700;" v-if="this.showTopupHistory">
              <div style="width: max-content">
                Chu kỳ tích lũy gần nhất:
                <span style="color: red"> {{ this.cumulativeList.totalPresent || 0 }}/5 </span>
              </div>
              <div style="width: max-content">
                Thể lệ chương trình tích lũy member Moyo:
                <span style="color: red; cursor: pointer;" @click="onShowModalRules">Xem thể lệ</span>
              </div>
            </div>
            <div class="d-loading-indicator" v-if="loading" :style="{ width: $mq === 'mobile' ? '100%' : '50%' }">
              <a-spin />
            </div>

            <div class="d-body-container" v-else>
              <a-row v-if="!viewStoreNew && !showTopupHistory">
                <a-col>
                  <a-row
                    class="d-card-bill-detail"
                    v-for="(bill, index) in bills"
                    :key="bill.billId + bill.sCompleteBillTime"
                    style="margin-left: 0px; margin-right: 0px"
                  >
                    <a-col :span="$mq === 'mobile' ? 24 : 4" style="margin-top: 10px !important">
                      <a-tag color="orange" v-if="!bill.isBookOnline">
                        <div>
                          {{ bill.sCompleteBillTime }}
                        </div>
                        <div>BillId: {{ bill.billId }}</div>
                      </a-tag>
                      <a-tag color="green" v-else>
                        <div>
                          {{ bill.sCompleteBillTime }}
                        </div>
                        <div>BillId: {{ bill.billId }}</div>
                      </a-tag>
                    </a-col>
                    <a-col
                      :span="$mq === 'mobile' ? 24 : 20"
                      v-if="bill.isProductReturnBill == 0"
                      style="margin-left: 0px"
                    >
                      <div class="d-card">
                        <a-row>
                          <a-col span="16">
                            <div class="d-title-card">
                              <a-icon type="rise" />
                              {{ bill.salonName }}
                            </div>
                          </a-col>
                          <a-col span="8">
                            <div class="d-online-booking-icon" v-if="bill.isBookOnline">
                              B
                            </div>
                          </a-col>
                        </a-row>
                        <div class="d-subtitle-card" v-show="!(bill.stylistName == '_' && bill.skinnerName == '_')">
                          Stylist:
                          <span class="staff-info" @click="handleClick">{{ bill.stylistName }}</span
                          >; Skinner:
                          <span class="staff-info" @click="handleClick">{{ bill.skinnerName }}</span>
                        </div>
                        <div class="d-subtitle-card" v-show="bill.isServiceBill">
                          CVTV_DV:
                          <span class="staff-info" @click="handleClick">{{
                            bill.serviceConsultant !== '_' ? bill.serviceConsultant : 'None'
                          }}</span
                          >; CVTV_SP:
                          <span class="staff-info" @click="handleClick">{{
                            bill.productConsultant !== '_' ? bill.productConsultant : 'None'
                          }}</span
                          >; BV:
                          <span class="staff-info" @click="handleClick">{{
                            bill.securityName !== '_' ? bill.securityName : 'None'
                          }}</span>
                        </div>
                        <div class="d-subtitle-card" v-show="bill.isServiceBill">
                          (CI: {{ bill.checkInTime !== null ? bill.checkInTime : 'None' }}; Giờ gội:
                          {{ bill.shampooTime !== null ? bill.shampooTime : 'None' }}; Giờ cắt:
                          {{ bill.hairCutTime !== null ? bill.hairCutTime : 'None' }}; CO:
                          {{ bill.checkOutTime !== null ? bill.checkOutTime : 'None' }})
                        </div>
                        <a-row v-show="bill.isServiceBill == 1">
                          <a-col :span="$mq === 'mobile' ? 24 : 6">
                            <div class="d-service-label">Dịch vụ:</div>
                          </a-col>
                          <a-col :span="$mq === 'mobile' ? 24 : 18">
                            <div class="d-service-group" v-for="service in bill.services" :key="service.name">
                              <div class="d-service-name">{{ service.name }}</div>
                              <div class="d-service-value">{{ service.totalPrice }}</div>
                            </div>
                          </a-col>
                        </a-row>
                        <a-row>
                          <a-col :span="$mq === 'mobile' ? 24 : 6">
                            <div class="d-service-label">Sản phẩm:</div>
                          </a-col>
                          <a-col :span="$mq === 'mobile' ? 24 : 18">
                            <div class="d-service-group" v-for="product in bill.products" :key="product.name">
                              <div class="d-service-name">{{ product.name }}</div>
                              <div class="d-service-value">{{ product.totalPrice }}</div>
                            </div>
                          </a-col>
                        </a-row>
                        <a-row>
                          <a-col :span="$mq === 'mobile' ? 24 : 6"></a-col>
                          <a-col :span="$mq === 'mobile' ? 24 : 18">
                            <div class="d-service-group">
                              <div class="d-service-name">Tổng tiền</div>
                              <div class="d-service-value">{{ bill.originTotal }}</div>
                            </div>
                            <div v-if="bill.discountTotal != '_'" class="d-service-group">
                              <div class="d-service-name">Tổng giảm</div>
                              <div class="d-service-value">{{ bill.discountTotal }}</div>
                            </div>
                            <div v-else class="d-service-group">
                              <div class="d-service-name">Phí ship</div>
                              <div class="d-service-value">{{ bill.shipPayment }}</div>
                            </div>
                            <div class="d-service-total">
                              <div class="d-service-name">TỔNG BILL</div>
                              <div class="d-service-value">{{ bill.billTotal }}</div>
                            </div>
                          </a-col>
                        </a-row>
                        <a-row>
                          <a-col :span="8">
                            <div class="d-service-label">Hình thức thanh toán:</div>
                          </a-col>
                          <a-col :span="8">
                            <div class="d-service-total">
                              <div class="d-service-name">{{ bill.paymentMethod }}</div>
                            </div>
                          </a-col>
                        </a-row>
                        <a-row>
                          <a-col :span="16">
                            <!-- <div class="d-service-label" v-show="bill.isServiceBill == 1">Đánh giá:</div> -->
                            <div class="d-subtitle-card" v-show="bill.isServiceBill == 1">
                              Rating Stylist:
                              {{ bill.ratingStylist !== '_' ? bill.ratingStylist : 'None' }}; Rating Skinner:
                              {{ bill.ratingSkinner !== '_' ? bill.ratingSkinner : 'None' }}; Rating Salon:
                              {{ bill.ratingSalon !== '_' ? bill.ratingSalon : 'None' }}
                            </div>
                          </a-col>

                          <!-- <a-col :span="8">
                      <div class="d-service-total">
                        <div class="d-service-name" v-show="bill.isServiceBill == 1">{{ bill.comment }}</div>
                      </div>
                    </a-col> -->
                          <a-col :span="8">
                            <a-button v-show="index >= 3" class="d-button-view-detail" @click="showModal(bill.images)"
                              >Xem chi tiết</a-button
                            >
                          </a-col>
                        </a-row>
                        <a-row v-show="bill.isServiceBill">
                          <a-col :span="12">
                            <div class="d-subtitle-card">
                              Dịch vụ khách chọn khi booking:
                            </div>
                          </a-col>
                          <a-col :span="12">
                            <template v-if="!!bill.serviceBooking && bill.serviceBooking.length">
                              <div
                                class="d-subtitle-card"
                                v-for="product in bill.serviceBooking"
                                :key="product.productName"
                              >
                                <div class="d-subtitle-card">- {{ product.productName }}</div>
                              </div>
                            </template>
                            <template v-else>
                              <div class="d-subtitle-card">None</div>
                            </template>
                          </a-col>
                        </a-row>
                      </div>
                      <a-row v-show="bill.isServiceBill && !!bill.listImage && bill.listImage.length && index < 3">
                        <div class="_img">
                          <div class="responsive" v-for="imageUrl in bill.listImage" :key="imageUrl">
                            <div class="gallery">
                              <a target="_blank" :href="imageUrl">
                                <img :src="imageUrl" alt="Anh khach hang" width="600" height="400" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </a-row>
                    </a-col>
                    <a-col :span="$mq === 'mobile' ? 24 : 20" v-else>
                      <div class="d-card">
                        <div class="d-title-card">
                          <a-icon type="rise" />
                          {{ bill.salonName }} {{ bill.buyDate }}
                        </div>
                        <div class="d-online-booking-icon" v-if="bill.isBookOnline">B</div>
                        <br />
                        <a-row>
                          <a-col :span="$mq === 'mobile' ? 24 : 6">
                            <div class="d-service-label">Sản phẩm:</div>
                          </a-col>
                          <a-col :span="$mq === 'mobile' ? 24 : 18">
                            <div class="d-service-group" v-for="product in bill.products" :key="product.name">
                              <div class="d-service-name">{{ product.name }}</div>
                              <div class="d-service-value">{{ product.totalPrice }}</div>
                            </div>
                          </a-col>
                        </a-row>
                        <a-row>
                          <a-col :span="$mq === 'mobile' ? 24 : 6"></a-col>
                          <a-col :span="$mq === 'mobile' ? 24 : 18">
                            <div class="d-service-total">
                              <div class="d-service-name">TỔNG BILL</div>
                              <div class="d-service-value">{{ bill.billTotal }}</div>
                            </div>
                          </a-col>
                        </a-row>
                        <a-row>
                          <a-col :span="8">
                            <div class="d-service-label">Hình thức trả lại:</div>
                          </a-col>
                          <a-col :span="8">
                            <div class="d-service-total">
                              <div class="d-service-name">{{ bill.paymentMethod }}</div>
                            </div>
                          </a-col>
                        </a-row>
                        <a-row>
                          <a-col :span="8"></a-col>
                          <a-col :span="8"></a-col>
                          <a-col :span="8">
                            <a-button class="d-button-view-detail-product-return">Bill trả SP</a-button>
                          </a-col>
                        </a-row>
                      </div>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <a-row
                class="d-pagination"
                v-show="!(bills.length < pageSize && currentPage === 1)"
                v-if="!viewStoreNew && !showTopupHistory"
              >
                <a-col :span="$mq === 'mobile' ? 24 : 12">
                  <a-button-group size="small">
                    <a-button type="primary" @click="previousPage"> <a-icon type="left" />Previous </a-button>
                    <a-button type="primary" @click="nextPage">Next <a-icon type="right"/></a-button>
                  </a-button-group>
                </a-col>
              </a-row>
              <a-row v-if="viewStoreNew && !showTopupHistory">
                <a-col>
                  <a-row
                    class="d-card-bill-detail"
                    style="margin-left: 0px; margin-right: 0px"
                    v-for="order in dataCustomerStore"
                    :key="order.code"
                  >
                    <a-col :span="$mq === 'mobile' ? 24 : 4" style="margin-top: 10px !important">
                      <a-tag color="green">{{ order.createdTime }}</a-tag>
                      <a-button
                        type="primary"
                        ghost
                        @click="onHandleFbSellerShop(order.code, order.id)"
                        class="btn-customer"
                        v-if="
                          order.status === status[2].enum ||
                            order.status === status[3].enum ||
                            order.status === status[4].enum
                        "
                      >
                        + Tác nghiệp chăm khách
                      </a-button>
                    </a-col>
                    <a-col :span="$mq === 'mobile' ? 24 : 20" class="d-box-store">
                      <div class="table-info-order">
                        <div class="item-info-order percent-with-20">
                          <div class="title-item-info-order">Mã đơn hàng</div>
                          <div class="content-item-info-order text-code-order">
                            {{ order.code }}
                          </div>
                        </div>
                        <div class="item-info-order percent-with-20">
                          <div class="title-item-info-order">TT đơn hàng</div>
                          <div class="content-item-info-order font-bold">
                            {{ order.statusOrder }}
                          </div>
                        </div>
                        <div class="item-info-order percent-with-30">
                          <div class="title-item-info-order">Hình thức thanh toán</div>
                          <div class="content-item-info-order font-bold">
                            {{ order.payments }}
                          </div>
                        </div>
                        <div class="item-info-order percent-with-30">
                          <div class="title-item-info-order">TT thanh toán</div>
                          <div class="content-item-info-order font-bold">
                            {{ order.statusPayments }}
                          </div>
                        </div>
                      </div>
                      <div class="table-info-order" v-if="order.fulfillmentCode.length > 0">
                        <div class="item-info-order percent-with-20">
                          <div class="title-item-info-order">Nhà vận chuyển</div>
                          <div class="content-item-info-order font-bold">
                            {{ order.fulfillmentCompany }}
                          </div>
                        </div>
                        <div class="item-info-order percent-with-20">
                          <div class="title-item-info-order">Mã vận đơn</div>
                          <div class="content-item-info-order text-code-order">
                            {{ order.fulfillmentCode }}
                          </div>
                        </div>
                      </div>
                      <div class="value-info-order">
                        Tổng tiền: <b>{{ order.orderValue }}</b>
                      </div>
                      <div class="list-product-info-order">
                        <div class="header-list-product-info-order">
                          <span v-if="order.listProductShow.length == 0" @click="showListProduct(true, order)"
                            >Sản phẩm trong đơn <a-icon type="caret-down"
                          /></span>
                          <span v-else @click="showListProduct(false, order)">Thu gọn <a-icon type="caret-up"/></span>
                        </div>
                        <div
                          class="content-list-product-info-order"
                          v-for="productItem in order.listProductShow"
                          :key="order.code + productItem.productId"
                        >
                          <div class="product-name">
                            {{ productItem.productName }}
                          </div>
                          <div class="product-version">
                            {{ productItem.productVersion }}
                          </div>
                          <div class="product-value">
                            <div class="left-product-value">
                              <div class="product-price" v-if="!productItem.productSales">
                                {{ productItem.productPrice }}
                              </div>
                              <div class="product-sales" v-else>
                                <div class="pull-box">
                                  <div></div>
                                </div>
                              </div>
                            </div>
                            <div class="right-product-value">x{{ productItem.productCount }}</div>
                          </div>
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <a-row class="d-pagination" v-show="showPageStore" v-if="viewStoreNew && !showTopupHistory">
                <a-col :span="$mq === 'mobile' ? 24 : 12">
                  <a-button-group size="small">
                    <a-button type="primary" @click="previousPageStore(selectChild ? phoneSelected : phoneNumber)">
                      <a-icon type="left" />Previous
                    </a-button>
                    <a-button type="primary" @click="nextPageStore(selectChild ? phoneSelected : phoneNumber)"
                      >Next <a-icon type="right"
                    /></a-button>
                  </a-button-group>
                </a-col>
              </a-row>
              <div v-if="showTopupHistory">
                <a-col>
                  <a-row
                    class="d-card-bill-detail"
                    v-for="(bill, index) in listTopupBill"
                    :key="index"
                    style="margin-left: 0px; margin-right: 0px"
                  >
                    <a-col :span="$mq === 'mobile' ? 24 : 4" style="margin-top: 10px !important">
                      <a-tag color="orange">
                        <div>
                          {{ moment(bill.createdDate).format('DD/MM/YYYY') }}
                        </div>
                        <div>BillId: {{ bill.billId }}</div>
                      </a-tag>

                      <a-tag color="red" v-if="bill.customerPhone" style="margin-top: 5px !important">
                        <div>Thanh toán cho [{{ bill.customerName }}] - [{{ bill.customerPhone }}]</div>
                      </a-tag>
                    </a-col>
                    <a-col :span="$mq === 'mobile' ? 24 : 20" style="margin-left: 0px">
                      <div class="d-card">
                        <a-row>
                          <a-col span="16">
                            <div class="d-title-card">
                              <a-icon type="rise" />
                              {{ bill.salonName }}
                              <div class="d-online-booking-icon" v-if="bill.isBookOnline">
                                B
                              </div>
                            </div>
                          </a-col>
                          <a-col
                            style="display: flex; justify-content: end;"
                            v-if="cumulativeList.bill && cumulativeList.bill.some(item => item === bill.billId)"
                            span="8"
                          >
                            <div class="cumulative">
                              Đã tích lũy
                            </div>
                          </a-col>
                        </a-row>

                        <a-row>
                          <a-col :span="8">
                            <div class="d-service-label">Hình thức thanh toán:</div>
                          </a-col>
                          <a-col :span="8">
                            <div class="d-service-total">
                              <div class="d-service-name">Topup ({{ Math.abs(bill.amount) }})</div>
                            </div>
                          </a-col>
                          <a-col :span="8" style="display: flex; justify-content: end;">
                            <div @click="toggleRow(bill.billMongoId)">
                              <div v-if="expandedRows[bill.billMongoId]">
                                <div class="d-see-more">
                                  Thu gọn
                                  <a-icon type="caret-up" />
                                </div>
                              </div>
                              <div v-else>
                                <div class="d-see-more">
                                  Xem chi tiết
                                  <a-icon type="caret-down" />
                                </div>
                              </div>
                            </div>
                          </a-col>
                        </a-row>

                        <a-row v-if="expandedRows[bill.billMongoId]">
                          <!-- Nội dung của a-row này sẽ được hiển thị khi người dùng nhấn vào "Xem thêm" -->
                          <div
                            class="d-subtitle-card"
                            v-for="staff in listDetailTopup[bill.billMongoId].staffServed"
                            :key="staff.id"
                          >
                            <span class="staff-info" v-if="staff.type === 1" @click="handleClick">
                              Stylist: {{ staff.name }} {{ staff.id }}
                            </span>
                            <span class="staff-info" v-else @click="handleClick">
                              Skinner: {{ staff.name }} {{ staff.id }}
                            </span>
                          </div>
                          <!-- <div class="d-subtitle-card">
                            CVTV_DV:
                            <span class="staff-info" @click="handleClick">{{
                              bill.serviceConsultant !== '_' ? bill.serviceConsultant : 'None'
                            }}</span>; CVTV_SP:
                            <span class="staff-info" @click="handleClick">{{
                              bill.productConsultant !== '_' ? bill.productConsultant : 'None'
                            }}</span>; BV:
                            <span class="staff-info" @click="handleClick">{{
                              bill.securityName !== '_' ? bill.securityName : 'None'
                            }}</span>
                          </div>
                          <div class="d-subtitle-card" v-show="bill.isServiceBill">
                            (CI: {{ bill.checkInTime !== null ? bill.checkInTime : 'None' }}; Giờ gội:
                            {{ bill.shampooTime !== null ? bill.shampooTime : 'None' }}; Giờ cắt:
                            {{ bill.hairCutTime !== null ? bill.hairCutTime : 'None' }}; CO:
                            {{ bill.checkOutTime !== null ? bill.checkOutTime : 'None' }})
                          </div> -->

                          <a-col :span="24" v-if="listDetailTopup[bill.billMongoId]">
                            <!-- dịch vụ -->
                            <a-row v-show="listDetailTopup[bill.billMongoId].services.length > 0">
                              <a-col :span="$mq === 'mobile' ? 24 : 6">
                                <div class="d-service-label">Dịch vụ:</div>
                              </a-col>
                              <a-col :span="$mq === 'mobile' ? 24 : 18">
                                <div
                                  class="d-service-group"
                                  v-for="service in listDetailTopup[bill.billMongoId].services"
                                  :key="service.name"
                                >
                                  <div class="d-service-name">{{ service.name }}</div>
                                  <!-- <div class="d-service-value">{{ service.price }}</div> -->
                                </div>
                              </a-col>
                            </a-row>
                            <!-- sản phẩm -->
                            <a-row>
                              <a-col :span="$mq === 'mobile' ? 24 : 6">
                                <div class="d-service-label">Sản phẩm:</div>
                              </a-col>
                              <a-col :span="$mq === 'mobile' ? 24 : 18">
                                <div
                                  class="d-service-group"
                                  v-for="product in listDetailTopup[bill.billMongoId].products"
                                  :key="product.name"
                                >
                                  <div class="d-service-name">{{ product.name }}</div>
                                  <!-- <div class="d-service-value">{{ product.price }}</div> -->
                                </div>
                              </a-col>
                            </a-row>
                            <a-row v-show="bill.bookingService">
                              <a-col :span="12">
                                <div class="d-subtitle-card">
                                  Dịch vụ khách chọn khi booking:
                                </div>
                              </a-col>
                              <a-col :span="12">
                                <template v-if="getServiceBooking(bill).length">
                                  <div
                                    class="d-subtitle-card"
                                    v-for="service in getServiceBooking(bill)"
                                    :key="service.Id"
                                  >
                                    <div class="d-subtitle-card">- {{ service.Name }}</div>
                                  </div>
                                </template>
                                <template v-else>
                                  <div class="d-subtitle-card">None</div>
                                </template>
                              </a-col>
                            </a-row>
                          </a-col>
                        </a-row>

                        <a-row>
                          <a-col :span="16">
                            <div class="d-subtitle-card" v-show="bill.isServiceBill == 1">
                              Rating Stylist:
                              {{ bill.ratingStylist !== '_' ? bill.ratingStylist : 'None' }}; Rating Skinner:
                              {{ bill.ratingSkinner !== '_' ? bill.ratingSkinner : 'None' }}; Rating Salon:
                              {{ bill.ratingSalon !== '_' ? bill.ratingSalon : 'None' }}
                            </div>
                          </a-col>
                        </a-row>
                      </div>
                      <a-row v-show="bill.isServiceBill && !!bill.listImage && bill.listImage.length && index < 3">
                        <div class="_img">
                          <div class="responsive" v-for="imageUrl in bill.listImage" :key="imageUrl">
                            <div class="gallery">
                              <a target="_blank" :href="imageUrl">
                                <img :src="imageUrl" alt="Anh khach hang" width="600" height="400" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </a-row>
                    </a-col>
                  </a-row>
                </a-col>
              </div>
              <a-row class="d-pagination" v-show="showTopupHistory" v-if="showTopupHistory">
                <a-col :span="$mq === 'mobile' ? 24 : 12">
                  <a-button-group size="small">
                    <a-button
                      type="primary"
                      @click="getBillTopup(selectChild ? phoneSelected : phoneNumber, topupHistoryPage - 1, 10)"
                    >
                      <a-icon type="left" />Previous
                    </a-button>
                    <a-button
                      type="primary"
                      @click="getBillTopup(selectChild ? phoneSelected : phoneNumber, topupHistoryPage + 1, 10)"
                      >Next <a-icon type="right"
                    /></a-button>
                  </a-button-group>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <a-col :span="$mq === 'mobile' ? 24 : 12">
            <div class="section" v-if="!!campaigns">
              <div class="section-title">Chiến dịch của khách hàng</div>
              <a-table
                :columns="campaignColumns"
                :data-source="campaigns"
                :locale="{
                  emptyText: 'Không có dữ liệu'
                }"
                :pagination="{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30']
                }"
              >
                <div slot="endDate" slot-scope="text">{{ text | formatDate }}</div>

                <div slot="campaignDetail" slot-scope="text, record">
                  <a-button type="link" @click="getCampaignBill(record)"> Bill sử dụng </a-button>
                </div>

                <div slot="campaignName" slot-scope="text, record">
                  <p>
                    {{ text }}
                    <br />
                    <i v-if="record.isDeleted === true || record.mktCampaign.isActive == false" style="color: red">
                      Hết hiệu lực</i
                    >
                  </p>
                </div>
                <!-- <div slot="completeBillTime" slot-scope="text">{{ text | formatDate }}</div> -->
              </a-table>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>

    <a-modal v-model="visible" title="Xem chi tiết" @ok="handleOk" :bodyStyle="{ height: 800 }">
      <div class="_img">
        <div class="responsive" v-for="imageUrl in cutImages" :key="imageUrl">
          <div class="gallery">
            <a target="_blank" :href="imageUrl">
              <img :src="imageUrl" alt="Anh khach hang" width="600" height="400" />
            </a>
          </div>
        </div>
      </div>
    </a-modal>
    <HandleEmployeeInfoDialog v-if="visibleEmployeeInfo" :visible.sync="visibleEmployeeInfo" :data="staffId" />
    <modal-feed-back :visibleFeedback.sync="visibleFeedback" :customerId="baseInfo.customerId" />
    <modal-feed-back-seller-shop
      :visibleFeedback.sync="visibleFeedbackSellerShop"
      :customerId="baseInfo.customerId"
      :shopOrderCode.sync="shopOrderCodeFeedback"
      :shopOrderId.sync="shopOrderIdFeedback"
    />
  </a-card>
</template>

<script>
import { mixin, mixinDevice } from '@/utils/mixin'
import { getLastCharterName, formatPriceWithDot } from '@/utils/string'
import { RepositoryFactory } from '@/api/RepositoryFactory'
import HandleEmployeeInfoDialog from './components/HandleEmployeeInfoDialog'
import ModalFeedBack from './components/ModalFeedBack'

import moment from 'moment'
import ModalFeedBackSellerShop from './components/ModalFeedBackSellerShop'
import ItemMemberInfo from './components/ItemMemberInfo.vue'
import ItemMemberSpending from './components/ItemMemberSpending.vue'
import changeDomain from './../../utils/checkDomain'

const CustomerRepo = RepositoryFactory.get('customerRepository')
const CampaignRepo = RepositoryFactory.get('campaignRepository')
const StoreRepository = RepositoryFactory.get('storeRepository')
const CustomerInsightRepository = RepositoryFactory.get('customerInsightRepository')
const CheckoutRepository = RepositoryFactory.get('checkoutRepository')
// const TimelineRepository = RepositoryFactory.get('timelineRepository')

const campaignColumns = [
  {
    title: 'Tên chiến dịch',
    dataIndex: ['mktCampaign', 'name'],
    key: 'mktCampaign.name',
    scopedSlots: { customRender: 'campaignName' }
  },
  {
    title: 'Ngày kết thúc',
    dataIndex: ['mktCampaign', 'endDate'],
    key: 'mktCampaign.endDate',
    scopedSlots: { customRender: 'endDate' }
  },
  {
    title: 'Mã khuyến mãi',
    dataIndex: ['voucherUsageData', 'code'],
    key: 'voucherUsageData.code'
  },
  {
    title: 'Số lần sử dụng tối đa',
    dataIndex: ['mktCampaign', 'maxUsage'],
    key: 'mktCampaign.maxUsage'
  },
  {
    title: 'Số lần đã sử dụng',
    dataIndex: ['used'],
    key: 'used'
  },
  {
    title: 'Thao tác',
    scopedSlots: { customRender: 'campaignDetail' }
  }
]

const memberLogColumns = [
  {
    title: 'Tên shine member',
    dataIndex: ['name'],
    key: 'name'
  },
  {
    title: 'Giá tiền',
    dataIndex: ['amount'],
    key: 'amount'
  },
  {
    title: 'Ngày bắt đầu',
    dataIndex: ['startDate'],
    key: 'startDate',
    scopedSlots: { customRender: 'startDate' }
  },
  {
    title: 'Ngày kết thúc',
    dataIndex: ['endDate'],
    key: 'endDate',
    scopedSlots: { customRender: 'endDate' }
  },
  {
    title: 'Ghi chú',
    dataIndex: ['note'],
    key: 'note'
  }
]

const productHistoryColumns = [
  {
    title: 'Sản phẩm',
    dataIndex: ['name'],
    key: 'name'
  },
  {
    title: 'Số lần',
    dataIndex: ['solanSD'],
    key: 'solanSD'
  },
  {
    title: 'Thanh toán',
    dataIndex: ['chiPhiDV'],
    key: 'chiPhiDV',
    scopedSlots: { customRender: 'priceDot' }
  }
]

const serviceHistoryColumns = [
  {
    title: 'Dịch vụ',
    dataIndex: ['name'],
    key: 'name'
  },
  {
    title: 'Số lần',
    dataIndex: ['solanSD'],
    key: 'solanSD'
  },
  {
    title: 'Thanh toán',
    dataIndex: ['chiPhiDV'],
    key: 'chiPhiDV',
    scopedSlots: { customRender: 'priceDotService' }
  }
]

const status = [
  {
    enum: 'PENDING',
    desc: 'Chờ xử lý'
  },
  {
    enum: 'CONFIRMED',
    desc: 'Đã xác nhận'
  },
  {
    enum: 'SHIPPING',
    desc: 'Đang lấy hàng'
  },
  {
    enum: 'DELIVERING',
    desc: 'Đang giao hàng'
  },
  {
    enum: 'COMPLETED',
    desc: 'Đã giao hàng'
  },
  {
    enum: 'CANCELLED',
    desc: 'Đã hủy'
  }
]

const paymentGateway = [
  {
    enum: 'COD',
    desc: 'COD'
  },
  {
    enum: 'TOPUP',
    desc: 'TOPUP'
  },
  {
    enum: 'VNPAY',
    desc: 'VNPAY'
  },
  {
    enum: 'ZALOPAY',
    desc: 'Zalo Pay'
  },
  {
    enum: 'CUSTOM',
    desc: 'Chuyển khoản'
  }
]

const paymentStatus = [
  {
    enum: 'PENDING',
    desc: 'Chờ thanh toán'
  },
  {
    enum: 'PAID',
    desc: 'Đã thanh toán'
  },
  {
    enum: 'FAILED',
    desc: 'Thanh toán thất bại'
  },
  {
    enum: 'CANCELLED',
    desc: 'Hủy thanh toán'
  }
]
export default {
  name: 'UsageHistory',
  mixins: [mixin, mixinDevice],
  components: { HandleEmployeeInfoDialog, ModalFeedBack, ModalFeedBackSellerShop, ItemMemberInfo, ItemMemberSpending },
  data() {
    return {
      phoneNumber: '',
      customerInfoNew: null,
      baseInfo: {},
      bills: [],
      billsForFilter: [],
      loading: false,
      visible: false,
      visibleCampaignDetail: false,
      visibleEmployeeInfo: false,
      staffId: undefined,
      cutImages: [],
      value: 1,
      currentPage: 1,
      pageSize: 10,
      isClickedBuySalon: false,
      isClickedBuyOnline: false,
      campaigns: null,
      memberLog: [],
      productHistories: [],
      serviceHistories: [],
      campaignColumns,
      memberLogColumns,
      productHistoryColumns,
      serviceHistoryColumns,
      listChildrenPhone: [],
      isShowPopup: false,
      listChildrenPhoneShow: [],
      listChildrenPhonePopup: [],
      numberChildrenPhoneHide: 0,
      phoneSelected: '',
      selectChild: false,
      childSelectItemId: 0,
      showMoreCustomerInfo: false,
      showMemberLog: false,
      showProductHistory: false,
      showServiceHistory: false,
      viewStoreNew: false,
      dataCustomerStore: [],
      status,
      paymentGateway,
      paymentStatus,
      pageStore: 1,
      totalDataStore: 0,
      showPageStore: false,
      editCustomerInfo: false,
      dateOfBirth: null,
      email: null,
      occupation: null,
      cityId: null,
      city: '',
      listCity: [],
      errorCity: false,
      districtId: null,
      district: '',
      listDistrict: [],
      listDistrictShow: [],
      errorDistrict: false,
      disableDistrict: true,
      wardId: null,
      ward: '',
      listWard: [],
      listWardShow: [],
      errorWard: false,
      disableWard: true,
      streetId: null,
      street: '',
      listStreet: [],
      listStreetShow: [],
      addressDetail: '',
      disableStreet: true,
      addressCustomerData: null,
      errorOccupation: false,
      reg: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
      errorEmail: false,
      regEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      addressText: null,
      disableBirthdayTime: null,
      customerId: 0,
      customerPhone: null,
      shineMemberInfo: null,
      visibleFeedback: false,
      visibleFeedbackSellerShop: false,
      shopOrderCodeFeedback: '',
      shopOrderIdFeedback: '',
      visibleRules: false,
      listTopupBill: [],
      showTopupHistory: false,
      expandedRows: {},
      listDetailTopup: [],
      cumulativeList: [],
      isMoyo: false,
      topupHistoryPage: 1
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isMoyo = changeDomain(window.location.hostname)
    })
  },
  methods: {
    moment,
    formatPriceWithDot,
    checkVakidateEmail() {
      if (this.email != null && this.email != '') {
        if (this.regEmail.test(this.email)) {
          this.errorEmail = false
        } else {
          this.errorEmail = true
        }
      } else {
        this.errorEmail = false
      }
    },
    checkVakidateInput() {
      if (this.occupation != null && this.occupation != '') {
        if (!this.reg.test(this.occupation)) {
          this.errorOccupation = false
        } else {
          this.errorOccupation = true
        }
      } else {
        this.errorOccupation = false
      }
    },
    filterCity(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    filterDistrict(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    filterWard(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    filterStreet(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleChange(key, value) {
      this.errorCity = false
      this.errorDistrict = false
      this.errorWard = false
      if (key == 'cityCustomer') {
        this.cityId = value
        this.getCity()
      } else if (key == 'districtCustomer') {
        this.districtId = value
        this.getDistrict()
      } else if (key == 'wardCustomer') {
        this.wardId = value
        this.getWard()
      } else if (key == 'streetCustomer') {
        this.streetId = value
        this.getStreet()
      } else if (key == 'birthdayCustomer') {
        if (value != null && value != '') {
          this.dateOfBirth = moment(value)
        }
      }
    },
    getCampaignBill(campaign) {
      debugger
      //console.log(this.billsForFilter[0].campaignBills)
      var billData = this.billsForFilter.filter(x => x.campaignBills.includes(campaign.mktCampaign.id))
      if (billData.length > 0) {
        this.bills = billData
        console.log('so bill theo campaign', this.bills)
      } else {
        this.bills = []
      }
    },
    getCity() {
      //lấy tên thành phố
      if (this.cityId != null && this.cityId > 0) {
        var getCity = this.listCity.filter(x => x.cityId == this.cityId)
        if (getCity != null && getCity.length > 0) {
          this.city = getCity[0].city
        }
        //lấy quận huyện theo thành phố
        this.listDistrictShow = this.listDistrict.filter(x => x.cityId == this.cityId)
      } else {
        this.city = ''
        this.listDistrictShow = []
      }
      this.disableDistrict = false
      this.disableWard = true
      this.disableStreet = true
      this.districtId = null
      this.district = null
      this.wardId = null
      this.ward = null
      this.listWardShow = []
      this.streetId = null
      this.street = null
      this.listStreetShow = []
    },
    getDistrict() {
      //lấy tên quận huyện
      if (this.districtId != null && this.districtId > 0) {
        var getDistrict = this.listDistrict.filter(x => x.districtId == this.districtId)
        if (getDistrict != null && getDistrict.length > 0) {
          this.district = getDistrict[0].district
        }
        //lấy phường xã theo quận huyện
        this.listWardShow = this.listWard.filter(x => x.districtId == this.districtId)
        //lấy đường theo quận huyện
        this.listStreetShow = this.listStreet.filter(x => x.districtId == this.districtId)
      } else {
        this.district = ''
        this.listWardShow = []
        this.listStreetShow = []
      }
      this.disableWard = false
      this.disableStreet = false
      this.wardId = null
      this.ward = null
      this.streetId = null
      this.street = null
    },
    getWard() {
      //lấy tên phường xã
      if (this.wardId != null && this.wardId > 0) {
        var getWard = this.listWard.filter(x => x.wardId == this.wardId)
        if (getWard != null && getWard.length > 0) {
          this.ward = getWard[0].ward
        }
      } else {
        this.ward = ''
      }
    },
    getStreet() {
      //lấy tên đường
      if (this.streetId != null && this.streetId > 0) {
        var getStreet = this.listStreet.filter(x => x.streetId == this.streetId)
        if (getStreet != null && getStreet.length > 0) {
          this.street = getStreet[0].street
        }
      } else {
        this.street = ''
      }
    },
    async saveCustomerInfo() {
      try {
        this.errorCity = false
        this.errorDistrict = false
        this.errorWard = false
        this.addressText = ''
        if (this.city == null || this.city == '') {
          this.errorCity = true
        }
        if (this.district == null || this.district == '') {
          this.errorDistrict = true
        }
        if (this.ward == null || this.ward == '') {
          this.errorWard = true
        }
        if (this.errorCity == false && this.errorDistrict == false && this.errorWard == false) {
          this.addressText =
            (this.addressDetail != null && this.addressDetail != '' ? this.addressDetail + ', ' : '') +
            (this.street != null && this.street != '' ? this.street + ', ' : '') +
            this.ward +
            ', ' +
            this.district +
            ', ' +
            this.city
          this.addressCustomerData = {
            cityId: this.cityId,
            districtId: this.districtId,
            wardId: this.wardId,
            streetId: this.streetId,
            addressDetail: this.addressDetail,
            addressFull: this.addressText
          }
        }
        if (!this.errorCity && !this.errorDistrict && !this.errorWard && !this.errorOccupation && !this.errorEmail) {
          var data = {
            customerId: this.customerId,
            customerPhone: this.customerPhone,
            name: null,
            dateOfBirth: moment(this.dateOfBirth).format('DD-MM-yyyy'),
            job: this.occupation,
            email: this.email,
            marriageStatus: null,
            addressCustomer: this.addressCustomerData,
            staffId: parseInt(this.$store.getters.user.userId),
            oS: 'Customer History'
          }
          await CustomerInsightRepository.updateCustomerData(data)
          this.customerInfoNew.dateOfBirth = data.dateOfBirth
          this.customerInfoNew.email = data.email
          this.customerInfoNew.occupation = data.job
          this.customerInfoNew.addressCustomer = data.addressCustomer
          this.editCustomerInfo = false
          this.$message.success('Cập nhật thành công.')
        }
      } catch (error) {
        console.log(error)
      }
    },
    cancelCustomerInfo() {
      this.addressCustomerData = null
      this.email = null
      this.occupation = null
      this.editCustomerInfo = false
      if (this.customerInfoNew.email != null) {
        this.email = this.customerInfoNew.email
      }
      if (this.customerInfoNew.occupation != null) {
        this.occupation = this.customerInfoNew.occupation
      }
      if (this.customerInfoNew.dateOfBirth != null) {
        this.dateOfBirth = this.customerInfoNew.dateOfBirth
      }
      if (
        this.customerInfoNew != null &&
        this.customerInfoNew.addressCustomer != null &&
        this.customerInfoNew.addressCustomer != ''
      ) {
        this.addressCustomerData = this.customerInfoNew.addressCustomer
      }
    },
    async showEditCustomerInfo() {
      try {
        this.disableBirthdayTime = current => {
          return current && (current < moment().subtract(100, 'years') || current > moment().subtract(3, 'years'))
        }
        this.editCustomerInfo = true
        var listAddressCustomer = null
        var getListAddress = localStorage.getItem('listAddressCustomer')
        if (getListAddress != null && getListAddress != '') {
          listAddressCustomer = JSON.parse(getListAddress)
        } else {
          var data = await CheckoutRepository.getListAddress()
          listAddressCustomer = data
          localStorage.setItem('listAddressCustomer', JSON.stringify(listAddressCustomer))
        }
        if (listAddressCustomer != null) {
          if (listAddressCustomer.cityAddress != null && listAddressCustomer.cityAddress.length > 0) {
            this.listCity = listAddressCustomer.cityAddress
          }
          if (listAddressCustomer.districtAddrress != null && listAddressCustomer.districtAddrress.length > 0) {
            this.listDistrict = listAddressCustomer.districtAddrress
          }
          if (listAddressCustomer.wardAddrress != null && listAddressCustomer.wardAddrress.length > 0) {
            this.listWard = listAddressCustomer.wardAddrress
          }
          if (listAddressCustomer.streetAddrress != null && listAddressCustomer.streetAddrress.length > 0) {
            this.listStreet = listAddressCustomer.streetAddrress
          }
        }
        this.errorCity = false
        this.errorDistrict = false
        this.disableDistrict = true
        this.errorWard = false
        this.disableWard = true
        this.disableStreet = true
        if (this.addressCustomerData != null && this.addressCustomerData != undefined) {
          this.cityId = this.addressCustomerData.cityId > 0 ? this.addressCustomerData.cityId : null
          this.getCity()
          this.districtId = this.addressCustomerData.districtId > 0 ? this.addressCustomerData.districtId : null
          this.getDistrict()
          this.wardId = this.addressCustomerData.wardId > 0 ? this.addressCustomerData.wardId : null
          this.getWard()
          this.streetId = this.addressCustomerData.streetId > 0 ? this.addressCustomerData.streetId : null
          this.getStreet()
          this.addressDetail = this.addressCustomerData.addressDetail
        }
      } catch (error) {
        throw error
      }
    },
    showListProduct(check, item) {
      for (var i = 0; i < this.dataCustomerStore.length; i++) {
        if (this.dataCustomerStore[i].code == item.code) {
          if (check) {
            this.dataCustomerStore[i].listProductShow = item.listProduct
          } else {
            this.dataCustomerStore[i].listProductShow = []
          }
        }
      }
    },
    showCustomerInfo(check) {
      this.showMoreCustomerInfo = check
    },
    async showMemberLogInfo(check) {
      this.showMemberLog = check
      if (this.baseInfo.customerId) {
        const { data } = await CustomerRepo.GetMemberlogInfo(this.baseInfo.customerId)
        this.memberLog = data
      }
    },
    async showProductHistoryInfo(check) {
      this.showProductHistory = check
      if (this.baseInfo.customerId && check) {
        this.productHistories = await CustomerRepo.GetProductHistory(this.baseInfo.customerId)
        const sumSL = this.productHistories.reduce((partialSum, a) => partialSum + a.solanSD, 0)
        const sumTT = this.productHistories.reduce((partialSum, a) => partialSum + a.chiPhiDV, 0)
        this.productHistories.unshift({
          id: 0,
          name: 'Tổng chi phí sản phẩm',
          solanSD: sumSL,
          chiPhiDV: sumTT
        })
      }
    },
    async showServiceHistoryInfo(check) {
      this.showServiceHistory = check
      if (this.baseInfo.customerId && check) {
        this.serviceHistories = await CustomerRepo.GetServiceHistory(this.baseInfo.customerId)
        const sumSL = this.serviceHistories.reduce((partialSum, a) => partialSum + a.solanSD, 0)
        const sumTT = this.serviceHistories.reduce((partialSum, a) => partialSum + a.chiPhiDV, 0)
        this.serviceHistories.unshift({
          id: 0,
          name: 'Tổng chi phí dịch vụ',
          solanSD: sumSL,
          chiPhiDV: sumTT
        })
      }
    },
    showPopup() {
      this.isShowPopup = !this.isShowPopup
    },
    async fetchCampaignHistory(customerId) {
      try {
        const data = await CampaignRepo.getCampaignHistoryByCustomerId(customerId)

        if (data.listCampaign != null) {
          data.listCampaign.forEach(item => {
            item.mktCampaign.name = `${item.mktCampaign.name} (${item.mktCampaign.id})`
            if (item.voucherUsageData && item.voucherUsageData.code) {
              if (item.voucherUsageData.completeBillTime) {
                item.voucherUsageData.statusText = 'Đã dùng'
              } else {
                if (item.mktCampaign.endDate && moment(item.mktCampaign.endDate).isBefore(moment())) {
                  item.voucherUsageData.statusText = 'Quá hạn'
                } else {
                  item.voucherUsageData.statusText = 'Chưa dùng'
                }
              }
            }
          })
          this.campaigns = data.listCampaign
        } else {
          this.campaigns = []
        }
      } catch (error) {
        console.log('fetchCampaignHistory error', error)
        this.$message.error(error, 2)
        this.campaigns = []
      }
    },
    //lay thong tin tich luy member
    async getShinememberInfo(customerId) {
      try {
        const data = await CustomerInsightRepository.GetCustomerShinememberInfo(customerId)

        if (data != null && data.data) {
          this.shineMemberInfo = data.data
          console.log('shineMemberInfo', this.shineMemberInfo)
        } else {
          this.shineMemberInfo = null
        }
      } catch (error) {
        console.log('getShinememberInfo error', error)
        this.$message.error(error, 2)
        this.campaigns = []
      }
    },
    async onSearchAtSalon(selectedChild, phoneNumberSearch) {
      this.showTopupHistory = false
      this.showMemberLog = false
      this.showProductHistory = false
      this.showServiceHistory = false
      this.memberLog = []
      this.productHistories = []
      this.serviceHistories = []
      this.customerInfoNew = null
      if (phoneNumberSearch !== '') {
        this.viewStoreNew = false
        if (phoneNumberSearch.length != 10) {
          this.$message.error('Số điện thoại của bạn phải đủ 10 ký tự!', 3)
        } else {
          try {
            this.selectChild = selectedChild
            this.phoneSelected = phoneNumberSearch
            this.loading = true
            this.baseInfo = {}
            this.billsForFilter = []
            this.bills = []
            var customerInfo = await CustomerRepo.GetCustomerInfo(phoneNumberSearch)
            console.log(customerInfo, 'customerInfo1')
            if (customerInfo?.data != null) {
              if (customerInfo.data == undefined) {
                this.$message.error('Không có thông tin', 2.5)
                return
              }
              this.baseInfo = customerInfo.data
              // var shineMember = customerInfo.data.memberName.split(',')
              this.baseInfo.shineMemberName = customerInfo.data.memberName
              this.baseInfo.shineMemberEndDate = customerInfo.data.memberEndTime
              if (!selectedChild) {
                this.childSelectItemId = 0
                this.listChildrenPhone = this.baseInfo.listCustomerChild
                this.listChildrenPhoneShow = []
                this.listChildrenPhonePopup = []
                this.numberChildrenPhoneHide = 0
                this.getKhachHangLienKet()
              }
              this.getShinememberInfo(this.baseInfo.customerId)
              await this.getCustomerData(this.baseInfo.customerId, phoneNumberSearch)
              this.fetchCampaignHistory(this.baseInfo.customerId)
              const result = await CustomerRepo.GetTransactionHistoryInfoAtSalon(this.baseInfo.customerId)
              if (result?.data) {
                this.baseInfo.customerTags = result.data.baseInfo.customerTags
                let tempBills = result.data.bills
                for (let i = 0; i < tempBills.length; i++) {
                  if (tempBills[i].isServiceBill && i < 3) {
                    let listImage
                    if (tempBills[i].images === '') {
                      listImage = []
                    } else {
                      listImage = tempBills[i].images.split(',', 4)
                    }
                    tempBills[i].listImage = listImage
                  }
                }
                this.billsForFilter = tempBills
                this.currentPage = 1
                this.bills = this.paginate(this.billsForFilter, this.pageSize, this.currentPage)
              }
            }
          } catch (error) {
            console.log(error)
            this.$message.error(error, 2.5)
          } finally {
            this.loading = false
          }
        }
      } else {
        this.$message.error('Bạn chưa điền số điện thoại!', 3)
      }
    },
    async onSearchAtStoreOnline(phoneNumberSearch) {
      this.showTopupHistory = false
      this.customerInfoNew = null
      if (phoneNumberSearch !== '') {
        this.viewStoreNew = false
        if (phoneNumberSearch.length != 10) {
          this.$message.error('Số điện thoại của bạn phải đủ 10 ký tự!', 3)
        } else {
          try {
            this.loading = true
            this.billsForFilter = []
            this.bills = []
            var customerInfo = await CustomerRepo.GetCustomerInfo(phoneNumberSearch)
            console.log(customerInfo, 'customerInfo2')
            if (customerInfo?.data != null) {
              if (customerInfo.data == undefined) {
                this.$message.error('Không có thông tin', 2.5)
                return
              }
              this.baseInfo = customerInfo.data
              // var shineMember = customerInfo.data.memberName.split(',')
              this.baseInfo.shineMemberName = customerInfo.data.memberName
              this.baseInfo.shineMemberEndDate = customerInfo.data.memberEndTime
              if (!this.selectChild) {
                this.listChildrenPhone = this.baseInfo.listCustomerChild
                this.listChildrenPhoneShow = []
                this.listChildrenPhonePopup = []
                this.numberChildrenPhoneHide = 0
                this.getKhachHangLienKet()
              }
              this.getShinememberInfo(this.baseInfo.customerId)
              await this.getCustomerData(this.baseInfo.customerId, phoneNumberSearch)
              var result = await CustomerRepo.GetTransactionHistoryInfoAtStoreOnline(
                this.baseInfo.customerId,
                phoneNumberSearch
              )
              if (result != null) {
                this.billsForFilter = result.bills
                this.currentPage = 1
                this.bills = this.paginate(this.billsForFilter, this.pageSize, this.currentPage)
              }
            }
          } catch (error) {
            this.$message.error(error, 2.5)
          } finally {
            this.loading = false
          }
        }
      } else {
        this.$message.error('Bạn chưa điền số điện thoại!', 3)
      }
    },
    async onSearchAtStoreOnlineNew(phoneNumberSearch, firstSearch) {
      this.showTopupHistory = false
      this.customerInfoNew = null
      this.dataCustomerStore = []
      this.showPageStore = false
      this.totalDataStore = 0
      if (firstSearch) {
        this.pageStore = 1
      }
      if (phoneNumberSearch !== '') {
        this.viewStoreNew = true
        if (phoneNumberSearch.length != 10) {
          this.$message.error('Số điện thoại của bạn phải đủ 10 ký tự!', 3)
        } else {
          try {
            this.loading = true
            this.billsForFilter = []
            this.bills = []
            var customerInfo = await CustomerRepo.GetCustomerInfo(phoneNumberSearch)
            if (customerInfo?.data != null) {
              if (customerInfo.data == undefined) {
                this.$message.error('Không có thông tin', 2.5)
                return
              }
              this.baseInfo = customerInfo.data
              // var shineMember = customerInfo.data.memberName.split(',')
              this.baseInfo.shineMemberName = customerInfo.data.memberName
              this.baseInfo.shineMemberEndDate = customerInfo.data.memberEndTime
              if (!this.selectChild) {
                this.listChildrenPhone = this.baseInfo.listCustomerChild
                this.listChildrenPhoneShow = []
                this.listChildrenPhonePopup = []
                this.numberChildrenPhoneHide = 0
                this.getKhachHangLienKet()
              }
              this.getShinememberInfo(this.baseInfo.customerId)
              await this.getCustomerData(this.baseInfo.customerId, phoneNumberSearch)
              var result = await StoreRepository.GetHistoryStoreOrder(this.baseInfo.customerId, this.pageStore)
              if (result != null && result.data != null && result.data.datas != null && result.data.datas.length > 0) {
                var tempData = result.data.datas
                for (var i = 0; i < tempData.length; i++) {
                  let createdTime = ''
                  if (tempData[i].createdAt != null && tempData[i].createdAt != '') {
                    createdTime = moment(tempData[i].createdAt, 'YYYY-MM-DDTHH:mm:ss')
                      .add(7, 'hours')
                      .format('DD/MM/YYYY HH:mm')
                  }
                  let statusOrder = ''
                  let getStatusOrder = status.filter(x => x.enum == tempData[i].status)
                  if (getStatusOrder != null && getStatusOrder.length > 0) {
                    statusOrder = getStatusOrder[0].desc
                  }
                  let payments = ''
                  let getPayments = paymentGateway.filter(x => x.enum == tempData[i].paymentGateway)
                  if (getPayments != null && getPayments.length > 0) {
                    payments = getPayments[0].desc
                  }
                  let statusPayments = ''
                  let getStatusPayments = paymentStatus.filter(x => x.enum == tempData[i].paymentStatus)
                  if (getStatusPayments != null && getStatusPayments.length > 0) {
                    statusPayments = getStatusPayments[0].desc
                  }
                  let orderValue = '0'
                  if (tempData[i].totalPrice > 0) {
                    orderValue = formatPriceWithDot(tempData[i].totalPrice)
                  }
                  let listProduct = []
                  if (tempData[i].orderItems != null && tempData[i].orderItems.length > 0) {
                    for (var j = 0; j < tempData[i].orderItems.length; j++) {
                      var productPrice = '0'
                      if (tempData[i].orderItems[j].price > 0) {
                        productPrice = formatPriceWithDot(tempData[i].orderItems[j].price)
                      }
                      var itemProduct = {
                        productId: tempData[i].orderItems[j].productId,
                        productName: tempData[i].orderItems[j].productName,
                        productVersion:
                          tempData[i].orderItems[j].variantName != null && tempData[i].orderItems[j].variantName != ''
                            ? 'Phiên bản ' + tempData[i].orderItems[j].variantName
                            : '',
                        productPrice: productPrice,
                        productCount: tempData[i].orderItems[j].quantity,
                        productSales: tempData[i].orderItems[j].isGift
                      }
                      listProduct.push(itemProduct)
                    }
                  }
                  let fulfillmentOrder = tempData[i].fulfillmentOrder
                  let fulfillmentCompany = ''
                  let fulfillmentCode = ''
                  if (fulfillmentOrder != null) {
                    fulfillmentCode = fulfillmentOrder.fulfillmentCode
                    fulfillmentCompany = fulfillmentOrder.fulfillmentCompany.name
                  }
                  let itemDataStore = {
                    createdTime: createdTime,
                    code: tempData[i].code,
                    statusOrder: statusOrder,
                    payments: payments,
                    statusPayments: statusPayments,
                    orderValue: orderValue + ' đ',
                    listProduct: listProduct,
                    listProductShow: [],
                    fulfillmentCode: fulfillmentCode,
                    fulfillmentCompany: fulfillmentCompany,
                    status: tempData[i].status,
                    id: tempData[i].id
                  }
                  this.dataCustomerStore.push(itemDataStore)
                }
                if (result.data.total > 10) {
                  this.showPageStore = true
                }
                this.totalDataStore = result.data.total
              }
            }
          } catch (error) {
            this.$message.error(error, 2.5)
          } finally {
            this.loading = false
          }
        }
      } else {
        this.$message.error('Bạn chưa điền số điện thoại!', 3)
      }
    },
    async getBillTopup(phoneNumberSearch, pageIndex, pageSize) {
      if (pageIndex === 0) {
        this.topupHistoryPage = 1
        return
      }

      if (phoneNumberSearch !== '') {
        this.loading = true
        if (this.baseInfo.customerId) {
          const { data } = await CustomerRepo.GetMoyoTopHistory(this.baseInfo.customerId, pageSize, pageIndex)
          const dataCumulative = await CustomerRepo.GetMoyoCumulativeList(this.baseInfo.customerId)
          if (data.length > 0) {
            this.topupHistoryPage = pageIndex
            const combinedHistory = data
            const filteredBills = combinedHistory.filter(bill => bill.billTitle !== 'Nạp thẻ thành viên')
            this.listTopupBill = filteredBills
            this.showTopupHistory = true
            this.cumulativeList = dataCumulative.data || []
            this.loading = false
          } else {
            this.loading = false
            this.$message.error('Không có lịch sử chi tiêu Topup!', 3)
          }
        }
      } else {
        this.loading = false
        this.$message.error('Bạn chưa điền số điện thoại!', 3)
      }
    },

    async previousPageStore(phoneNumberSearch) {
      if (this.pageStore > 1) {
        this.pageStore = this.pageStore - 1
        await this.onSearchAtStoreOnlineNew(phoneNumberSearch, false)
      }
    },
    async nextPageStore(phoneNumberSearch) {
      if (this.pageStore * 10 < this.totalDataStore) {
        this.pageStore = this.pageStore + 1
        await this.onSearchAtStoreOnlineNew(phoneNumberSearch, false)
      }
    },
    async getCustomerData(customerId, customerPhone) {
      this.customerId = customerId
      this.customerPhone = customerPhone
      this.addressCustomerData = null
      this.email = null
      this.occupation = null
      //lấy thông tin cá nhân khách hàng
      var customerInfoNewData = await CustomerInsightRepository.GetCustomerInfo(customerId, customerPhone)
      this.customerInfoNew = customerInfoNewData.data
      if (this.customerInfoNew.email != null) {
        this.email = this.customerInfoNew.email
      }
      if (this.customerInfoNew.occupation != null) {
        this.occupation = this.customerInfoNew.occupation
      }
      if (this.customerInfoNew.dateOfBirth != null) {
        this.dateOfBirth = this.customerInfoNew.dateOfBirth
      }
      if (
        this.customerInfoNew != null &&
        this.customerInfoNew.addressCustomer != null &&
        this.customerInfoNew.addressCustomer != ''
      ) {
        this.addressCustomerData = this.customerInfoNew.addressCustomer
      }
    },
    handleClick(e) {
      var text = e.target.innerText
      if (text === undefined || text === 'None' || text === '') {
        return
      }
      var splittedName = text.split(' ')
      this.visibleEmployeeInfo = true
      this.staffId = splittedName[splittedName.length - 1]
    },
    handleOk() {
      this.visible = false
    },
    showModal(images) {
      if (images == '') {
        this.$message.warn('Không có thông tin chi tiết khác!', 3)
        return
      }
      if (images != null && images != undefined) {
        this.cutImages = images.split(',', 4)
      } else {
        this.cutImages = ''
      }
      this.visible = true
    },
    onChangeRadioButton(e) {
      const value = e.target.value
      if (value == 1) {
        this.onSearchAtSalon()
      }
      if (value == 2) {
        this.onSearchAtStoreOnline()
      }
    },
    previousPage() {
      if (this.currentPage == 1) return
      this.currentPage = this.currentPage - 1
      var data = this.paginate(this.billsForFilter, this.pageSize, this.currentPage)
      if (data != null) {
        this.bills = data
      }
    },
    nextPage() {
      this.currentPage = this.currentPage + 1
      var data = this.paginate(this.billsForFilter, this.pageSize, this.currentPage)
      if (data != null && data.length > 0) {
        this.bills = data
      } else {
        this.currentPage = this.currentPage - 1
      }
    },
    paginate(array, page_size, page_number) {
      return array.slice((page_number - 1) * page_size, page_number * page_size)
      // if (this.isClickedBuySalon) {
      //   return array.filter(x => x.isBookOnline != true).slice((page_number - 1) * page_size, page_number * page_size)
      // }
      // if (this.isClickedBuyOnline) {
      //   return array.filter(x => x.isBookOnline == true).slice((page_number - 1) * page_size, page_number * page_size)
      // }
    },
    async viewHistoryChild(item) {
      if (item != null && item.customerPhone != null && item.customerPhone != '') {
        await this.onSearchAtSalon(true, item.customerPhone)
        this.childSelectItemId = item.id
        this.isShowPopup = false
      }
    },
    getKhachHangLienKet() {
      if (this.listChildrenPhone != null && this.listChildrenPhone.length > 3) {
        this.numberChildrenPhoneHide = this.listChildrenPhone.length - 3
      }
      if (this.listChildrenPhone != null && this.listChildrenPhone.length > 0) {
        for (var i = 0; i < this.listChildrenPhone.length; i++) {
          var name = this.listChildrenPhone[i].customerName
          if (
            this.listChildrenPhone[i].customerName != null &&
            this.listChildrenPhone[i].customerName != '' &&
            this.listChildrenPhone[i].customerName.length > 20
          ) {
            name = this.listChildrenPhone[i].customerName.slice(0, 20) + '...'
          }
          var item = {
            id: this.listChildrenPhone[i].id,
            customerName: name,
            charaterLastName: getLastCharterName(this.listChildrenPhone[i].customerName),
            customerPhone: this.listChildrenPhone[i].customerPhone,
            isSelected: false
          }
          if (i < 3) {
            this.listChildrenPhoneShow.push(item)
          } else {
            this.listChildrenPhonePopup.push(item)
          }
        }
      }
    },
    onShowModalFeedBack() {
      this.visibleFeedback = true
    },
    onShowModalRules() {
      this.visibleRules = true
    },
    onHandleFbSellerShop(code, id) {
      this.visibleFeedbackSellerShop = true
      this.shopOrderCodeFeedback = code
      this.shopOrderIdFeedback = id
    },
    async toggleRow(billMongoId) {
      if (billMongoId !== '') {
        if (!this.listDetailTopup[billMongoId]) {
          try {
            const { data } = await CustomerRepo.GetMoyoTopHistoryDetail(billMongoId)
            if (data) {
              this.$set(this.expandedRows, billMongoId, !this.expandedRows[billMongoId])
              this.$set(this.listDetailTopup, billMongoId, { ...data, billMongoId: billMongoId })
            }
          } catch (error) {
            console.error('Error fetching data:', error)
            // Xử lý lỗi nếu cần
          }
        } else {
          this.$set(this.expandedRows, billMongoId, !this.expandedRows[billMongoId])
        }
      }
    },
    getServiceBooking(bill) {
      try {
        if (bill.bookingService) {
          return JSON.parse(bill.bookingService)
        }
      } catch (error) {
        console.error('Failed to parse bookingService:', error)
      }
      return []
    }
  },

  async created() {
    if (this.$route.params.phone != null && this.$route.params.phone != undefined) {
      this.phoneNumber = this.$route.params.phone
      await this.onSearchAtSalon(false, this.$route.params.phone)
    }
  }
}
</script>

<style scoped>
.content-item-info-order {
  word-wrap: break-word;
}

.cumulative {
  background: #ef4e24;
  padding: 5px 10px;
  border-radius: 10px;
  color: white;
  text-align: center;
  width: max-content;
}

.d-see-more {
  color: #ef4e24;
  cursor: pointer;
  margin-top: 5px;
  margin-right: 0px;
}

.ant-tag {
  display: block !important;
  white-space: unset;
}

.text-note-street {
  display: block;
  color: #000;
  padding-top: 6px;
  font-size: 10px;
}

.error-input {
  display: block;
  color: red !important;
  padding-top: 6px;
}

.field-select-address-right {
  float: right;
  width: 50%;
  padding-left: 5px;
}

.field-select-address-left {
  float: left;
  width: 50%;
  padding-right: 5px;
}

.field-select-address {
  float: left;
  width: 100%;
  padding-bottom: 10px;
}

.label-field {
  float: left;
  width: 100px;
  line-height: 32px;
}

.input-field {
  float: left;
  width: calc(100% - 100px);
}

.header-list-product-info-order {
  cursor: pointer;
}

.product-sales {
  height: 16px;
  position: relative;
}

.pull-box {
  margin: 0 auto;
  transform: translate(-50%, -50%);
  width: 72px;
  height: 16px;
  position: absolute;
  left: 40px;
  top: 10px;
}

.pull-box > div {
  text-align: center;
}

.pull-box > div:before {
  background: #e54d3e;
  content: 'Quà tặng';
  display: block;
  transform: skew(-16deg);
  width: 72px;
  height: 16px;
  color: #fff;
  font-size: 10px;
}

.product-name {
  color: #2962ff;
}

.product-version {
  font-size: 12px;
  color: #222222;
}

.product-price {
  font-size: 14px;
  color: #000;
}

.content-list-product-info-order {
  padding-left: 10px;
  padding-top: 10px;
}

.left-product-value {
  float: left;
}

.right-product-value {
  float: right;
  font-size: 14px;
  color: #222222;
}

.content-list-product-info-order,
.product-value {
  float: left;
  width: 100%;
}

.list-product-info-order {
  padding-top: 10px;
  float: left;
  width: 100%;
}

.value-info-order {
  padding-top: 10px;
  float: left;
  width: 100%;
}

.text-code-order {
  font-weight: bold;
  color: #2962ff;
}

.font-bold {
  font-weight: bold;
}

.percent-with-30 {
  width: 30%;
}

.percent-with-20 {
  width: 20%;
}

.d-box-store {
  padding: 12px 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.table-info-order {
  float: left;
  width: 100%;
}

.item-info-order {
  float: left;
  text-align: center;
}

.btn-cancel-customer-info {
  float: right;
  margin-right: 10px;
}

.btn-edit-customer-info {
  float: right;
}

.customer-info-container {
  padding: 16px;
  border: 1px solid #e8e8e8 !important;
  border-radius: 2px;
  float: left;
  width: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.item-customer-info {
  padding-bottom: 12px;
  float: left;
  width: 100%;
}

.header-customer-info {
  padding-bottom: 13px;
}

.header-customer-info span {
  cursor: pointer;
}

.d-customer-info {
  padding-top: 21px;
  padding-bottom: 20px;
}

.other-children-phone {
  position: absolute;
  width: 240px;
  top: 46px;
  left: -100px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  padding-left: 27px;
  padding-top: 17px;
  padding-bottom: 17px;
  background-color: #fff;
  z-index: 1000;
}

.block-other-children-phone {
  float: left;
  width: 100%;
  padding-bottom: 8px;
}

.text-title-children-phone {
  float: left;
  line-height: 40px;
  padding-right: 15px;
  font-size: 14px;
  color: #000;
}

.list-children-phone {
  padding-left: 23px;
  border-left: 1px solid #c4c4c4;
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
}

.block-children-phone {
  width: auto;
  float: left;
  padding-right: 15px;
  position: relative;
}

.name-children-phone {
  float: left;
  line-height: 40px;
  color: #000;
  padding-left: 14px;
}

.cricle-children-phone {
  height: 39px;
  width: 39px;
  background: #d8d8d8;
  border-radius: 50%;
  float: left;
  line-height: 37px;
  text-align: center;
  font-size: 20px;
  color: #000;
}

.block-children-phone:nth-child(2) .cricle-children-phone {
  color: #fff;
  background: #20bf55;
  border: 1px solid #20bf55;
}

.block-children-phone:nth-child(3) .cricle-children-phone {
  color: #fff;
  background: #0b4f6c;
  border: 1px solid #0b4f6c;
}

.block-children-phone:nth-child(4) .cricle-children-phone {
  color: #fff;
  background: #01baef;
  border: 1px solid #01baef;
}

.block-other-children-phone:nth-child(1n + 0) .cricle-children-phone {
  color: #fff;
  background: #bf1a2f;
  border: 1px solid #bf1a2f;
}

.block-other-children-phone:nth-child(2n + 0) .cricle-children-phone {
  color: #fff;
  background: #f00699;
  border: 1px solid #f00699;
}

.block-other-children-phone:nth-child(3n + 0) .cricle-children-phone {
  color: #fff;
  background: #454e9e;
  border: 1px solid #454e9e;
}

.block-other-children-phone:nth-child(4n + 0) .cricle-children-phone {
  color: #fff;
  background: #018e42;
  border: 1px solid #018e42;
}

.block-other-children-phone:nth-child(5n + 0) .cricle-children-phone {
  color: #fff;
  background: #f7d002;
  border: 1px solid #f7d002;
}

.cricle-children-phone.active,
.cricle-children-phone:hover,
.cricle-children-phone:focus {
  border-color: blue !important;
}

.d-value {
  display: block;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.d-label {
  display: block;
  text-align: center;
}

.d-info-card {
  border: 1px solid #e8e8e8 !important;
  padding: 15px 0px;
  border-radius: 2px;
  height: 15;
  line-height: 15px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  min-height: 100px;
  margin-bottom: 16px;
}

.d-tag-info-card {
  border: 1px solid #e8e8e8 !important;
  padding: 15px 0px;
  border-radius: 2px;
  min-height: 100px;
  height: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.d-button {
  margin-right: 20px !important;
  display: inline;
}

.d-group-button {
  display: inline;
}

/* .d-all-button {
  /* color: white;
  background-color: #1890ff !important; *
} */
.d-salon-button {
  border: 1px solid orange !important;
}

.d-online-button {
  border: 1px solid green !important;
}

.d-button-focus:focus {
  background-color: #d6eafc !important;
}

.d-body-container {
  margin-top: 30px;
}

.d-card {
  border: 1px solid #e8e8e8 !important;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-bottom: 10px;
}

.d-online-booking-icon {
  width: 20px;
  height: 20px;
  color: white;
  font-weight: bold;
  background-color: black;
  text-align: center;
  border-radius: 50%;
  float: right;
}

.d-title-card {
  font-weight: bold;
}

.d-subtitle-card {
  font-style: italic;
  margin-top: 5px;
  margin-bottom: 10px;
}

.d-service-label {
  font-weight: bold;
  margin: 5px;
}

.d-service-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
  background-color: #f2f2f2;
}

.d-service-name {
  font-style: italic;
}

.d-service-value {
  font-style: italic;
}

.d-service-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold !important;
  margin: 5px;
}

.d-button-view-detail {
  color: white;
  background-color: green !important;
  float: right;
}

.d-button-view-detail-product-return {
  color: white;
  background-color: black !important;
  float: right;
}

.d-card-bill-detail {
  margin: 10px;
}

.d-loading-indicator {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.d-group-customer-info {
  margin-bottom: 10px;
}

.d-group-radio {
  text-align: center;
  border: 1px solid #e8e8e8 !important;
  padding: 15px 0px;
  margin-bottom: 10px;
  border-radius: 2px;
}

.d-pagination {
  text-align: center;
}

div.gallery {
  border: 1px solid #ccc;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  width: 100%;
  height: auto;
}

div.desc {
  padding: 15px;
  text-align: center;
}

* {
  box-sizing: border-box;
}

._img {
  display: flex;
}

.responsive {
  padding: 0 6px;
  width: 25%;
}

.customer-tags-container {
  border: 1px solid #333;
  padding: 5px;
}

.customer-tag-title {
  margin-left: 30px;
  color: #808080;
  margin-bottom: 10px;
}

.customer-tag-item {
  margin-left: 30px;
  margin-bottom: 3px;
}

.staff-info {
  cursor: pointer;
}

.staff-info:hover {
  text-decoration: underline;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.section {
  padding-left: 10px;
}

.expire-gold-member {
  display: inline-block;
  border: 1px solid #d35d3c;
  box-sizing: border-box;
  color: #d35d3c;
  padding: 1px 3px;
}

.unexpired-gold-member {
  display: inline-block;
  border: 1px solid #3cd35d;
  box-sizing: border-box;
  color: #3cd35d;
  padding: 1px 3px;
}

.btn-customer {
  height: auto;
  white-space: inherit;
  margin-right: 8px;
  margin-top: 8px;
}

.b-member {
  padding: 8px;
  display: flex;
  flex-flow: column;
  gap: 4px;
}

.rule {
  .rule-title-1 {
    font-weight: 500;
  }

  .rule-text-1 {
  }
}
</style>
